import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ element }) => {
  const code = localStorage.getItem("key");

  return code ? element : <Navigate to="/access" />;
};

export default ProtectedRoutes;
