import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Access = () => {
  const [passcode, setPasscode] = useState("");
  console.log("🚀 + Access + passcode:", passcode);
  console.log("🚀 + Access + passcode:", passcode);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("key", passcode);
    if (passcode === "369MV@INF") {
      navigate("/");
    } else {
      alert("Incorrect passcode!");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <h1 className="mb-4">Coming Soon</h1>
        {/* <p className="mb-4">Enter your email to be notified when we launch:</p> */}
        <form
          className="form-inline justify-content-center"
          onSubmit={handleSubmit}
        >
          <div className="form-group mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Passcode"
              onChange={(e) => setPasscode(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary mb-2 ml-2 "
            style={{ width: "100%" }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Access;
