import React from "react";
import { Link, useLocation } from "react-router-dom";
import emailicon from "../../src/assets/images/email-Icon.png";

function VerifyEmail() {
  const location = useLocation();
  const email = location.state?.email || "your email";

  return (
    <div className="text-center m-5">
      <h3>Verify Your Email</h3>
      <img src={emailicon} style={{ width: "300px", marginTop: "1px" }} alt="Email verification icon"/>
      <h6>A verification link has been sent to <u>{email}</u>. Please check your inbox and click the link to verify your account.</h6>
      <p>If you don't see the email, please check your spam folder.</p>
      <button className="m-5">
        <Link to="/">Go Back to Home</Link>
      </button>
    </div>
  );
}

export default VerifyEmail;