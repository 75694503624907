import axios from "axios";
import Cookies from "js-cookie";



const API_URL = process.env.REACT_APP_API_URL;
const token = "token 46b6b3377cfd39f:d6eab93b8a46e1b";
//const token = process.env.REACT_APP_TOKEN;

const login = (formData, setError) => {
  // Extracting email and password from formData
  const { email, password } = formData;

  // Prepare data object for login request
  const postData = {
    usr: email,
    pwd: password,
  };

  axios
    .post(API_URL + "/api/method/hdir.api.login", postData)
    .then((response) => {
      console.log(response.data);
      // Check if login status is successful
      if (response.data.message.status_code === 200) {
        // Extract user data from response
        const userData = {
          full_name: response.data.full_name,
          uid: response.data.message.user,
          role: response.data.message.role,
        };

        // Store user token and data in localStorage
        localStorage.setItem(
          "userToken",
          `token ${response.data.message.token}`
        );
        localStorage.setItem("user", JSON.stringify(userData));
        Cookies.set('user', JSON.stringify(userData));
        Cookies.set("userToken",
          `token ${response.data.message.token}`);
        if (response.data.message.token) {
          window.open("https://hadmin.prismaticsoft.com", "_blank");
        }
      } else {
        // If login fails, set error message after a delay
        setTimeout(() => {
          setError("Cannot login with credentials");
        }, 1000);
      }
    })
    .catch((error) => console.error(error));
};

const sendOTP = async (formData, setOtpModal, setShow) => {
  // Extracting mobile number from formData
  const { number: mobile } = formData;

  // Prepare user data object with mobile number
  const userData = {
    mobile: mobile,
  };

  try {
    // Sending OTP request to API
    const response = await axios.post(
      API_URL + "/api/method/hdir.api.signup",
      userData,
      {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
          "content-type": "application/json",
        },
      }
    );

    // Setting OTP modal visibility
    setOtpModal(true);

    // Extracting message from response
    const msg = response.data.message;

    // Show modal if OTP is successfully sent
    if (msg === "OTP Send") {
      setShow(true);
    }

    // Returning message
    return msg;
  } catch (error) {
    // Logging error
    console.error(error);
  }
};

const checkOTP = async (details, otp) => {
  // Extracting number from details object
  const { number } = details;

  // Prepare user data object with mobile number and OTP
  const userData = {
    mobile: number,
    otp: otp,
  };

  try {
    // Sending OTP verification request to API
    const response = await axios.post(
      API_URL + "/api/method/hdir.api.verifyOTP",
      userData,
      {
        headers: {
          Authorization: token, // token is defined elsewhere
          "Content-Type": "application/json",
        },
      }
    );
    console.log("🚀 + checkOTP + response:", response);

    // Handling different response cases
    if (typeof response.data.message === "object") {
      // Extracting user details from response
      const { full_name, api_key, api_secret, email, mobile_no, type } =
        response.data.message;

      // Constructing user object
      const user = {
        full_name,
        api_key,
        api_secret,
        uid: email,
        mobile_no,
      };

      // Storing user data in localStorage for authentication
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userType", type);
      localStorage.setItem("userToken", `token ${api_key}:${api_secret}`);

      // Return success message
      return "OTP VERIFIED & LOGGING IN";
    } else if (response.data.message === "Invalid OTP") {
      // Return error message for invalid OTP
      return response.data.message;
    } else {
      // Return error message for other cases
      return "No user found";
    }
  } catch (error) {
    // Log and re-throw any errors
    console.error(error);
    throw error;
  }
};

const register = async (details) => {
  // Extract details from the parameter object for readability
  const { name, email, number, facility_type, password } = details;

  // Prepare user data object
  const userData = {
    full_name: name,
    email: email,
    mobile: number,
    role: facility_type,
    // Avoiding password for security reasons
    // new_password: password,
  };

  // Log user data for debugging
  console.log("User Data:", userData);

  try {
    // Sending registration request to API
    const response = await axios.post(
      API_URL + "/api/method/hdir.api.user_register",
      userData,
      {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
          "content-type": "application/json",
        },
      }
    );

    // Extracting user details from the response
    const newUserData = {
      full_name: response.data.message.full_name,
      api_key: response.data.message.api_key,
      api_secret: response.data.message.api_secret,
      uid: response.data.message.email,
    };

    //setting new password if registration success

    if (response.data.message.api_key) {
      const newPassword = {
        new_password: password,
      };
      try {
        const res = await axios.put(
          `${API_URL}/api/resource/User/${email}`,
          newPassword,
          {
            headers: {
              Authorization: process.env.REACT_APP_TOKEN,
              "content-type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log("🚀 + register + error:", error);
      }
    }

    // Logging user details for debugging
    // console.log("New User Details:", newUserData);

    // Storing user data in localStorage for authentication
    localStorage.setItem("user", JSON.stringify(newUserData));
    localStorage.setItem("userType", "Auth");

    // Constructing and storing user token for API authentication
    localStorage.setItem(
      "userToken",
      `token ${response.data.message.api_key}:${response.data.message.api_secret}`
    );

    // Return success message
    return "Registration successful!";
  } catch (err) {
    // If registration fails due to duplicate email, return error message
    return "Email id already exists!";
  }
};

const getUserFromCookies = () => {
  return Cookies.get("user");
};

const clearStorage = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("guestuser");
  localStorage.removeItem("userType");
  localStorage.removeItem("userToken");
  localStorage.removeItem("userIP");
  localStorage.removeItem("userRole");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export { login, checkOTP, sendOTP, register, clearStorage, getCurrentUser,getUserFromCookies };
